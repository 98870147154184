import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plaid"
export default class extends Controller {
  static targets = [
    "account",
    "accountName",
    "accountType",
    "bank",
    "button",
    "fields",
    "loading",
    "token"
  ]

  handleLink() {
    const controller = this;
    (async function() {
      const achResponse = await fetch("/api/ach_token", {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      })
      const achToken = await achResponse.json()
      var linkHandler = Plaid.create({
        token: achToken.link_token,
        onLoad: function() {
            // The Link module finished loading.
        },
        onSuccess: function(public_token, metadata) {
          controller.buttonTarget.classList.add("hidden")
          controller.fieldsTarget.classList.remove("hidden")
          controller.accountTarget.value = `xxxxxxxx${metadata.account.mask}`
          controller.accountNameTarget.value = metadata.account.name
          const accountType = metadata.account.subtype
          controller.accountTypeTarget.value = accountType[0].toUpperCase() + accountType.substring(1)
          controller.bankTarget.value = metadata.institution.name;

          (async function() {
            const bankAccountResponse = await fetch("/api/bank_account_token", {
              body: JSON.stringify({
                public_token: public_token,
                account_id: metadata.account_id
              }),
              method: "POST",
              headers: { "Content-Type": "application/json" }
            })
            const bankAccountTokenJson = await bankAccountResponse.json()
            controller.tokenTarget.value = bankAccountTokenJson.stripe_bank_account_token
            controller.loadingTarget.classList.add("hidden")
          })()
        },
        onExit: function(err, metadata) {
          // The user exited the Link flow.
          if (err != null) {
              // The user encountered a Plaid API error
              // prior to exiting.
          }
          // metadata contains information about the institution
          // that the user selected and the most recent
          // API request IDs.
          // Storing this information can be helpful for support.
        },
      });
      linkHandler.open();
    })();
  }
}
