import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin"
export default class extends Controller {
  static targets = [ "offCanvasMenu", "offCanvasMenuContainer", "offCanvasMenuOverlay", "offCanvasMenuCloseButton", "profileDropdown" ]
  static values = {
    leftNavOpen: { type: Boolean, default: false },
    profileDropdownOpen: { type: Boolean, default: false }
  }

  toggleLeftNav() {
    if (this.leftNavOpenValue) {
      this.closeLeftNav()
    } else {
      this.openLeftNav()
    }
  }

  closeLeftNav() {
    this.offCanvasMenuContainerTarget.classList.add("pointer-events-none", "hidden")
    this.offCanvasMenuOverlayTarget.classList.remove("opacity-100")
    this.offCanvasMenuOverlayTarget.classList.add("opacity-0")
    this.offCanvasMenuTarget.classList.remove("translate-x-0")
    this.offCanvasMenuTarget.classList.add("-translate-x-full")
    this.offCanvasMenuCloseButtonTarget.classList.remove("opacity-100")
    this.offCanvasMenuCloseButtonTarget.classList.add("opacity-0")
    this.leftNavOpenValue = false
  }

  openLeftNav() {
    this.offCanvasMenuContainerTarget.classList.remove("pointer-events-none", "hidden")
    this.offCanvasMenuOverlayTarget.classList.remove("opacity-0")
    this.offCanvasMenuOverlayTarget.classList.add("opacity-100")
    this.offCanvasMenuTarget.classList.remove("-translate-x-full")
    this.offCanvasMenuTarget.classList.add("translate-x-0")
    this.offCanvasMenuCloseButtonTarget.classList.remove("opacity-0")
    this.offCanvasMenuCloseButtonTarget.classList.add("opacity-100")
    this.leftNavOpenValue = true
  }

  toggleProfileDropdown() {
    if (this.profileDropdownOpenValue) {
      this.closeProfileDropdown()
    } else {
      this.openProfileDropdown()
    }
  }

  closeProfileDropdown() {
    this.profileDropdownTarget.classList.remove("transform", "opacity-100", "scale-100")
    this.profileDropdownTarget.classList.add("transform", "opacity-0", "scale-95", "pointer-events-none", "hidden")
    this.profileDropdownOpenValue = false
  }

  openProfileDropdown() {
    this.profileDropdownTarget.classList.remove("transform", "opacity-0", "scale-95", "pointer-events-none", "hidden")
    this.profileDropdownTarget.classList.add("transform", "opacity-100", "scale-100")
    this.profileDropdownOpenValue = true
  }
}
