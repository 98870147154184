import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'step' ]
  static values = { index: Number }

  initialize() {
    this.showCurrentStep()
  }

  next() {
    this.indexValue++
  }

  previous() {
    this.indexValue--
  }

  indexValueChanged() {
    this.showCurrentStep()
  }

  showCurrentStep() {
    this.stepTargets.forEach((element, index) => {
      element.hidden = index != this.indexValue
    })
  }

  changeStep(event) {
    event.preventDefault()
    let target = event.target
    if (target.tagName != 'A') {
      target = target.closest('a')
    }
    this.indexValue = target.dataset.index
  }
}
