import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'form' ]

  removePrice(event) {
    event.preventDefault()
    const form = event.target.closest('.price-form')
    form.remove()
  }

  addPrice(event) {
    event.preventDefault()
    const button = event.target
    const container = event.target.closest('div.flex.justify-end')

    let time = new Date().getTime()
    let buttonId = button.dataset.id
    let regexp = new RegExp(buttonId, 'g')
    let newFields = button.dataset.fields.replace(regexp, time)
    container.insertAdjacentHTML('beforebegin', newFields)
  }
}
