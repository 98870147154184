import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'button', 'dropdown', 'mobile' ]

  toggleDropdown(event) {
    event.preventDefault()

    if (this.dropdownTarget.classList.contains('hidden')) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.dropdownTarget.classList.add('animate__fadeIn')
    this.dropdownTarget.classList.remove('hidden')
  }

  hide(event) {
    if (event && (this.dropdownTarget.contains(event.target) || this.buttonTarget.contains(event.target)) && event.target.tagName != 'A') {
      event.preventDefault()
      return
    }
    this.buttonTarget.setAttribute('aria-expanded', 'false')
    this.dropdownTarget.classList.remove('animate__fadeIn')
    this.dropdownTarget.classList.add('hidden')
  }

  toggleMobileMenu(e) {
    const button = e.target
    if (this.mobileTarget.classList.contains('hidden')) {
      this.mobileTarget.classList.add('animate__fadeIn')
      this.mobileTarget.classList.remove('animate__fadeOut')
      this.mobileTarget.classList.remove('hidden')
      button.setAttribute('aria-expanded', true)
    } else {
      this.mobileTarget.classList.remove('animate__fadeIn')
      this.mobileTarget.classList.add('hidden')
      button.setAttribute('aria-expanded', false)
    }
  }
}
