import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from '@rails/activestorage'

export default class extends Controller {
  static targets = [ ]

  image_upload_handler(blobInfo, success, failure, _progress) {
    const upload = new DirectUpload(
      blobInfo.blob(),
      '/rails/active_storage/direct_uploads',
    )
    upload.create((error, blob) => {
      if (error) {
        failure(error)
      } else {
        let signed_id = blob.signed_id
        let url = `${window.location.origin}/rails/active_storage/blobs/${signed_id}/${blobInfo.filename()}`
        success(url)
      }
    })
  }

  connect() {
    tinymce.init({
      selector: '.tinymce',
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste help wordcount',
        'emoticons image'
      ],
      toolbar: 'undo redo | formatselect fontselect | ' +
        ' bold italic backcolor | image emoticons | alignleft aligncenter ' +
        ' alignright alignjustify | bullist numlist outdent indent | ' +
        ' removeformat code | help',
      image_title: true,
      image_uploadtab: true,
      images_upload_handler: this.image_upload_handler,
      relative_urls : false,
      remove_script_host : false,
      document_base_url : window.location.origin
    })
  }

  disconnect() {
    tinymce.remove()
  }

  toggleVariableAmount(event) {
    const variableAmount = event.target.closest('.price-form').querySelector('.variable-amount')
    if (event.target.value == 0) {
      variableAmount.classList.remove('hidden')
    } else {
      variableAmount.classList.add('hidden')
    }
  }
}
