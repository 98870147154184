import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-double-click"
export default class extends Controller {
  static values = {
    clicked:{ type: Boolean, default: false }
  }

  disableDoubleClick(event) {
    if (this.clickedValue) {
      event.preventDefault()
      return
    }

    this.clickedValue = true
    let link = event.target
    if (event.target.tagName !== "A") {
      link = event.target.parentElement
    }

    link.classList.add("opacity-70")
  }
}
