import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fee"
export default class extends Controller {
  static targets = [ "includedFee" ]

  changeIncludedFee(event) {
    let input = event.target
    let value = input.valueAsNumber
    let feeType = input.dataset.feeType
    let includedFee = null
    if (feeType === "credit_card") {
      includedFee = (((value + 0.3) / (1 - 2.9 / 100)) - value)
    } else if (feeType === "bank") {
      includedFee = (((value + 0) / (1 - 0.8 / 100)) - value)
    }
    let roundedFee = this.round(includedFee, 2)
    if (isNaN(roundedFee)) {
      this.includedFeeTarget.innerHTML = "$0.00"
    }
    else {
      this.includedFeeTarget.innerHTML = `$${roundedFee.toLocaleString('en-US', { minimumFractionDigits:2 })}`
    }
  }

  round(value, decimals) {
    return Number(Math.ceil(value +'e'+ decimals) +'e-'+ decimals).toFixed(decimals);
  }
}
