import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-group"
export default class extends Controller {
  static targets = [ "label" ]

  selectRadioInput(event) {
    this.labelTargets.forEach(label => {
      // Remove selected classes
      if (label.classList.contains("bg-blue-600")) {
        label.classList.remove("bg-blue-600", "border-transparent", "text-white", "hover:bg-blue-700")
        label.classList.add("bg-white", "border-gray-200", "text-gray-900", "hover:bg-gray-50")
      }
    })
    event.target.parentElement.classList.remove("bg-white", "border-gray-200", "text-gray-900", "hover:bg-gray-50")
    event.target.parentElement.classList.add("bg-blue-600", "border-transparent", "text-white", "hover:bg-blue-700")
  }
}
