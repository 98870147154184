import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "address",
    "bank",
    "bankAccountSelect",
    "bankButton",
    "bankDetails",
    "bankError",
    "bankFields",
    "bankToken",
    "city",
    "fields",
    "form",
    "includedFee",
    "loading",
    "name",
    "price",
    "quantity",
    "savedCards",
    "savedCardsField",
    "state",
    "submitButton",
    "token",
    "total",
    "zip"
  ]
  static values = {
    key: String,
    initialize: Boolean,
    selectedPriceId: Number
  }

  connect() {
    this.stripe = Stripe(this.keyValue)
    this.elements = this.stripe.elements()

    if (this.initializeValue) {
      this.initializeStripe()
    }
  }

  initializeStripe() {
    const options = {
      style: {
        base: {
          'backgroundColor': '#fff',
          'color': 'inherit',
          'fontFamily': 'inherit',
          'fontSize': '16px',
          'lineHeight': '24px',
          'padding': '8px 12px'
        },
      },
    }

    this.cardNumberElement = this.elements.create('cardNumber', options)
    this.cardExpiryElement = this.elements.create('cardExpiry', options)
    this.cardCvcElement = this.elements.create('cardCvc', options)
    this.cardNumberElement.mount('#payment_card_number')
    this.cardExpiryElement.mount('#payment_expiration_date')
    this.cardCvcElement.mount('#payment_cvc')

    if (this.hasFieldsTarget) {
      this.fieldsTarget.classList.remove('hidden')
    }
  }

  destroyStripe() {
    if (this.hasFieldsTarget) {
      this.fieldsTarget.classList.add('hidden')
    }

    if (this.cardNumberElement && !this.cardNumberElement._destroyed) {
      this.cardNumberElement.destroy()
    }
    if (this.cardExpiryElement && !this.cardExpiryElement._destroyed) {
      this.cardExpiryElement.destroy()
    }
    if (this.cardCvcElement && !this.cardCvcElement._destroyed) {
      this.cardCvcElement.destroy()
    }
  }

  updateBankAccountPaymentMethod(event) {
    const select = event.target
    if (select.value) {
      this.bankButtonTarget.classList.add("hidden")
      this.bankFieldsTarget.classList.add("hidden")
      let inputs = this.bankDetailsTarget.querySelectorAll("input")
      for (let i = 0; i < inputs.length; ++i) {
        let input = inputs[i]
        input.value = ""
      }
    } else {
      this.bankButtonTarget.classList.remove("hidden")
    }
  }

  updateCreditCardPaymentMethod(event) {
    const select = event.target
    if (select.value) {
      this.destroyStripe()
    } else {
      this.initializeStripe()
    }
  }

  updatePrice(event) {
    const selected = event.target
    const selectedClasses = ["bg-blue-600", "text-white"]
    // reset all button stylings
    Array.from(document.querySelectorAll(".price-button")).forEach((el) => {
      el.classList.remove(...selectedClasses)
      el.querySelectorAll("p.custom-amount").forEach((el) => el.classList.add("text-emerald-500")) // readd green text
    })
    // add selected styling
    const selectedButton = selected.closest(".price-button")
    selectedButton.classList.add(...selectedClasses)
    selectedButton.querySelectorAll("p").forEach((el) => el.classList.remove("text-emerald-500")) // remove green text
    // update total
    this.selectedPriceIdValue = selectedButton.dataset.priceid
    this.updateTotal()
  }

  updateTotal() {
    if (!this.hasTotalTarget) {
      return
    }

    const selectedButton = document.querySelector(`.price-button[data-priceid='${this.selectedPriceIdValue}']`)
    let customAmountField = selectedButton.querySelector("input[type='number']")
    let quantity = 1
    if (this.hasQuantityTarget) {
      quantity = parseInt(this.quantityTarget.value)
    }
    let total = 0
    let fee = 0
    if(customAmountField) {
      total = parseFloat(customAmountField.value)
    } else {
      total = parseFloat(selectedButton.querySelector("label").innerText.replace(/[^\d.-]/g, ''))
    }
    let feeSpan = selectedButton.querySelector("span[data-fee-target='includedFee']")
    if(feeSpan) {
      fee = parseFloat(feeSpan.innerText.replace(/[^\d.-]/g, ''))
    }
    this.totalTarget.innerText = `$${Number(this.round((total + fee) * quantity, 2)).toLocaleString('en-US', { minimumFractionDigits: 2 })} ${selectedButton.dataset.pricePerText}`
  }

  updateIncludedFees(event) {
    const selectedPaymentMethod = event.target
    let feeType = "card"
    if (selectedPaymentMethod.value == "bank") {
      feeType = "bank"
    }
    this.includedFeeTargets.forEach((includedFee) => {
      let value = parseFloat(includedFee.dataset[`${feeType}Fee`])
      includedFee.innerHTML = `$${value.toLocaleString('en-US', { minimumFractionDigits: 2 })}`
    })

    document.querySelectorAll("input[data-feeType]").forEach((input) => {
      if (feeType === "card") {
        input.dataset.feeType = "credit_card"
      } else {
        input.dataset.feeType = "bank"
      }
    })
  }

  round(value, decimals) {
    return Number(Math.ceil(value +'e'+ decimals) +'e-'+ decimals).toFixed(decimals);
  }

  selectPrice(event) {
    this.priceTargets.forEach((price) => {
      if (price.value == event.srcElement.dataset.priceid) {
        price.click()
      }
    })
  }

  focusText(event) {
    event.target.select()
  }

  hideBankError() {
    this.bankErrorTarget.classList.add("hidden")
  }

  handleFormSubmit(event) {
    event.preventDefault()
    let controller = this
    controller.submitButtonTarget.disabled = true
    if (controller.hasLoadingTarget) {
      controller.formTarget.classList.add('hidden')
      controller.loadingTarget.classList.remove('hidden')
    }
    const form = event.currentTarget
    const paymentMethod = controller.selectedPaymentMethod()
    if (!controller.cardNumberElement || controller.cardNumberElement._destroyed) {
      if (paymentMethod == "bank" && controller.hasBankTokenTarget && controller.bankTokenTarget.value === "" && controller.hasBankAccountSelectTarget && controller.bankAccountSelectTarget.value === "") {
        controller.bankErrorTarget.classList.remove("hidden")
        controller.submitButtonTarget.disabled = false
        if (controller.hasLoadingTarget) {
          controller.formTarget.classList.remove('hidden')
          controller.loadingTarget.classList.add('hidden')
        }
      } else {
        form.submit()
      }
    } else {
      let address = {}
      if (controller.hasAddressTarget) {
        address = {
          name: controller.nameTarget.value,
          address_line1: controller.addressTarget.value,
          address_city: controller.cityTarget.value,
          address_state: controller.stateTarget.value,
          address_zip: controller.zipTarget.value,
          address_country: 'US'
        }
      }
      controller.stripe.createToken(controller.cardNumberElement, address).then((result) => {
        if (result.error) {
          controller.submitButtonTarget.disabled = false
          if (controller.hasLoadingTarget) {
            controller.formTarget.classList.remove('hidden')
            controller.loadingTarget.classList.add('hidden')
          }
          console.log(result.error)
          return
        }

        controller.tokenTarget.value = result.token.id
        form.submit()
      })
    }
  }

  showPaymentMethod(event) {
    const selected = event.target
    const paymentMethod = selected.value
    if (paymentMethod == "bank") {
      // show bank button
      this.bankTarget.classList.remove("hidden")
      this.bankTokenTarget.required = true
      this.destroyStripe()
      if (this.hasBankAccountSelectTarget) {
        this.bankAccountSelectTarget.disabled = false
      }
      if (this.hasSavedCardsTarget) {
        this.savedCardsFieldTarget.disabled = true
        this.savedCardsTarget.classList.add("hidden")
      } else {
        this.fieldsTarget.classList.add("hidden")
      }
    } else {
      // show credit card button
      this.bankTarget.classList.add("hidden")
      this.bankTokenTarget.required = false
      if (this.hasBankAccountSelectTarget) {
        this.bankAccountSelectTarget.disabled = true
      }
      if (this.hasSavedCardsTarget) {
        this.savedCardsFieldTarget.disabled = false
        this.savedCardsTarget.classList.remove("hidden")
        if (this.savedCardsFieldTarget.value === "") {
          this.initializeStripe()
          this.fieldsTarget.classList.remove("hidden")
        }
      } else {
        this.initializeStripe()
        this.fieldsTarget.classList.remove("hidden")
      }
    }
  }

  selectedPaymentMethod() {
    document.querySelector("input[name='payment-method']:checked")?.value || "credit"
  }
}
