import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "chevron", "content" ]

  toggleCollapse() {
    if (this.contentTarget.classList.contains("hidden")) {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    this.contentTarget.classList.remove("hidden")
    this.chevronTarget.innerHTML = "<i class=\"fas fa-chevron-up\"></i>"
  }

  close() {
    this.contentTarget.classList.add("hidden")
    this.chevronTarget.innerHTML = "<i class=\"fas fa-chevron-down\"></i>"
  }
}
