import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'field', 'image' ]

  clickField() {
    this.fieldTarget.click()
  }

  previewImage(event) {
    const input = event.target
    const image = this.imageTarget
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = function(e) {
        image.src = e.target.result
      }

      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }
}
