import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'source' ]

  copy(event) {
    this.sourceTarget.select()
    navigator.clipboard.writeText(this.sourceTarget.value)
    this.displaySuccess(event.currentTarget)
  }

  displaySuccess(target) {
    let previousHTML = target.innerHTML
    target.disabled = true
    target.innerHTML = '<span class="inline-flex items-center px-3 rounded-full text-sm font-medium bg-emerald-100 text-emerald-800 animate__animated animate__fadeOut animate__delay-2s"><i class="fa-solid fa-clipboard mr-2"></i> Copied!</span>'
    setTimeout(function() {
      target.innerHTML = previousHTML
      target.disabled = false
    }, 3000);
  }
}

