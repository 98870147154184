import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stars"
export default class extends Controller {
  static targets = [ "comments", "form", "rating", "star" ]
  static values = { locked: { type: Boolean, default: false } }

  fillStars(event) {
    if (this.lockedValue) {
      return
    }

    let index = parseInt(event.currentTarget.dataset.index)
    this.starTargets.slice(0, index).forEach(star => {
      if (star.innerHTML == '<i class="fa-regular fa-star"></i>') {
        star.innerHTML = '<i class="fa-solid fa-star text-yellow-500"></i>'
      }
    })
    this.ratingTarget.value = index
  }

  emptyStars() {
    if (this.lockedValue) {
      return
    }

    this.starTargets.forEach(star => {
      star.innerHTML = '<i class="fa-regular fa-star"></i>'
    })
    this.ratingTarget.value = ""
  }

  lockStars(event) {
    this.lockedValue = false
    this.fillStars(event)
    this.lockedValue = true
    this.showCommentsField()
    this.submitForm()
  }

  showCommentsField() {
    this.commentsTarget.classList.remove("hidden")
    this.commentsTarget.classList.add("animate__animated", "animate__fadeIn")
  }

  submitForm() {
    if (this.formTarget.requestSubmit){
      this.formTarget.requestSubmit()
    } else {
      this.formTarget.submit()
    }
  }
}
